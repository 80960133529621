var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "container__admin" },
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            { staticClass: "mt-4", attrs: { "align-v": "center" } },
            [
              _c(
                "b-col",
                { staticClass: "pt-4", attrs: { "align-self": "start" } },
                [
                  _c(
                    "router-link",
                    {
                      staticClass: "text-secondary",
                      attrs: { to: "/welcome" }
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "arrow-left", "aria-hidden": "true" }
                      }),
                      _c("span", [_vm._v(" " + _vm._s(_vm.$t("admin.back")))])
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass:
                    "bg-white mt-4 p-3 navbar-profile justify-content-center",
                  attrs: { "align-self": "end", md: "3", xl: "2" }
                },
                [_c("MenuUser")],
                1
              )
            ],
            1
          ),
          _c("b-row", { staticClass: "my-4" }, [
            _c("h3", [_vm._v(_vm._s(_vm.$t("admin.title")))])
          ]),
          _c("b-row", [
            _c(
              "div",
              { staticStyle: { width: "100%" } },
              [
                _c(
                  "b-card",
                  {
                    staticClass: "mb-4",
                    attrs: { title: "Card Title", "no-body": "" }
                  },
                  [
                    _c(
                      "b-card-header",
                      { attrs: { "header-tag": "nav" } },
                      [
                        _c(
                          "b-nav",
                          {
                            staticClass: "tabs-card",
                            attrs: { "card-header": "", tabs: "" }
                          },
                          [
                            _vm.configRol.administrador.pestana.pdf.permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "pencil-square",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.$t("admin.pdfTitle")))
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.users.permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/users",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "person",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.$t("admin.users.title")))
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.pharmacy.permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/pharmacy",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        src: require("../assets/images/farmacia-dark.png"),
                                        alt: "farmacia"
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.pharmacy.title"))
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.auth_programs
                              .permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/auth",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "ui-checks",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.$t("admin.auth.title")))
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana
                              .auth_discounts &&
                            _vm.configRol.administrador.pestana.auth_discounts
                              .permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/authdos",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "ui-checks",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("admin.authDiscounts.title")
                                      )
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.trm.permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/trm",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "credit-card",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v("TRM")
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.medicines
                              .permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/medicine",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mr-2",
                                      staticStyle: { width: "20px" },
                                      attrs: {
                                        src: require("../assets/images/Medicine.svg"),
                                        alt: "medicine"
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.medicines.title"))
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.integrator
                              .permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/integrator",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mr-2",
                                      staticStyle: { width: "20px" },
                                      attrs: {
                                        src: require("../assets/images/diferenciadores-icon.svg"),
                                        alt: "integrator"
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.integrator.title"))
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.topCards.permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/top-cards",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("img", {
                                      staticClass: "mr-2",
                                      staticStyle: { width: "20px" },
                                      attrs: {
                                        src: require("../assets/images/historico-icon.svg"),
                                        alt: "top"
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.$t("admin.top.title")))
                                  ]
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.adminRoles
                              .permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/rol",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "people",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(_vm._s(_vm.$t("admin.role.title")))
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.adminSucursales
                              .permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/bussiness",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "diagram3",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.bussiness.title"))
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.adminSucursales
                              .permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/adminPrograms",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "journal-medical",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("admin.bussiness.programsAdmin")
                                      )
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.branchMatrix &&
                            _vm.configRol.administrador.pestana.branchMatrix
                              .permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/islas&matriz",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "file-spreadsheet",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("admin.branchMatriz.branch")
                                      ) +
                                        " & " +
                                        _vm._s(
                                          _vm.$t("admin.branchMatriz.matrix")
                                        )
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana
                              .adminDiscounts &&
                            _vm.configRol.administrador.pestana.adminDiscounts
                              .permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/discounts",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "cash",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.discounts.title"))
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.adminSucursales
                              .permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/adminMac",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "box",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "admin.bussiness.productRegeneraAdmin"
                                        )
                                      )
                                    )
                                  ],
                                  1
                                )
                              : _vm._e(),
                            _vm.configRol.administrador.pestana.adminSucursales
                              .permiso
                              ? _c(
                                  "b-nav-item",
                                  {
                                    attrs: {
                                      to: "/admin/bundles",
                                      exact: "",
                                      "exact-active-class": "active"
                                    }
                                  },
                                  [
                                    _c("b-icon", {
                                      staticClass: "mr-2",
                                      attrs: {
                                        icon: "bag",
                                        "aria-hidden": "true"
                                      }
                                    }),
                                    _vm._v(
                                      _vm._s(_vm.$t("admin.bussiness.bundle"))
                                    )
                                  ],
                                  1
                                )
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    ),
                    _c("b-card-body", [_c("router-view")], 1)
                  ],
                  1
                )
              ],
              1
            )
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }